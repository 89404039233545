import { darken, lighten } from "color2k";

export const THEME = {
    white: {
        background: "#FFF",
        default: "#2B70C9",
        light: "#D5E2F4",
        dark: "#1453A3",
        text: "text-gray-dark",
    },
    gray: {
        background: "#F9F9F9",
        default: "#8C909C",
        light: "#ECECEC",
        dark: "#151616",
        text: "text-gray-dark",
    },
    default: {
        background: "#2B70C9",
        text: "text-white",
        default: "#2B70C9",
        light: "#D5E2F4",
        dark: "#1453A3",
    },
    primary: {
        background: "#FFF",
        text: "text-white",
        default: "#EA7244",
        light: "#FCEAE3",
        dark: "#C2552A",
    },
    blue: {
        background: "#1CB0F6",
        text: "text-white",
        default: "#1CB0F6",
        dark: darken("#1CB0F6", 0.1),
        light: lighten("#1CB0F6", 0.4),
    },
    green: {
        background: "#33BD72",
        dark: darken("#33BD72", 0.1),
        default: "#33BD72",
        light: lighten("#33BD72", 0.45),
        text: "text-white",
    },
    red: {
        background: "#FF7878",
        dark: darken("#FF7878", 0.1),
        default: "#FF7878",
        light: lighten("#FF7878", 0.22),
        text: "text-white",
    },
    orange: {
        background: "#FF9600",
        dark: darken("#FF9600", 0.1),
        default: "#FF9600",
        light: lighten("#FF9600", 0.425),
        text: "text-white",
    },
    yellow: {
        background: "#f9ba39",
        dark: darken("#f9ba39", 0.1),
        default: "#f9ba39",
        light: lighten("#f9ba39", 0.425),
        text: "text-white",
    },
    indigo: {
        background: "#575FCC",
        text: "text-white",
        default: "#575FCC",
        dark: darken("#575FCC", 0.5),
        light: lighten("#575FCC", 0.35),
    },
};

export type Themes = keyof typeof THEME;
